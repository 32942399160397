module.exports = [
	{
		id: 'jhu-start-date',
		type: 'start-date',
		default: true,
		exclude: ['/apply/', '/thank-you/', '/ai-b/', '/ds-b/'],
	},
	{
		id: 'jhu-start-date-cro',
		type: 'start-date-cro',
		default: false,
		include: ['/ai-b/', '/ds-b/'],
		countdown: '2025-01-08',
	},
];
