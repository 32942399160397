import siteBanners from './../../_data/banners';
import Cookies from 'js-cookie';

class Banners {
    constructor() {
        this.alertBanner = document.querySelector('#alert-banner');
        const self = this;
        
        if (this.alertBanner == null) return;
        
        window.addEventListener('DOMContentLoaded', function(e) {
            var currentPath = window.location.pathname;
            
            // Get all valid banners for this URL
            var validBanners = []; 
            siteBanners.forEach(function(banner) {
                // Skip if this page is excluded
                if (banner.exclude && banner.exclude.indexOf(currentPath) > -1) {
                    return;
                }
        
                if ((banner.include && banner.include.indexOf(currentPath) > -1) ||
                    (banner.default) ||
                    (!banner.include)) {
                    
                    // Date range check
                    if (banner.dates) {
                        if (banner.dates.start) {
                            var startDate = banner.dates.start.split('/')
                            startDate = new Date(startDate[2],startDate[0]-1,startDate[1]);
                            if (startDate > Date.now()) return;
                        }
                        if (banner.dates.end) {
                            var endDate = banner.dates.end.split('/')
                            endDate = new Date(endDate[2],endDate[0]-1,endDate[1]);
                            if (endDate < Date.now()) return;
                        }
                    }
                    validBanners.push(banner);
                } 
            });
        
            //console.log(validBanners)
        
            // If there are multiple that apply, let's show them in reverse order
            // (ie. latest additions first, presumably default shows last)
        
            if (validBanners.length > 1) validBanners.reverse();
        
            // If they've not previously hidden this banner, show it
            for (var i = 0; i < validBanners.length; i++) {
                var banner = validBanners[i]
                if (banner.id && typeof Cookies.getJSON('hidebanners') != "undefined" && Cookies.getJSON('hidebanners').indexOf(banner.id) > -1) {
                    continue;
                }
        
                self.showBanner(banner);
        
                break;
            }

            // Click listener for alert banner links (including close)
            //var alertBanner = document.querySelector('#alert-banner');
            self.alertBanner.addEventListener('click', function(e) {
                e.preventDefault();
                //console.log(e.target);

                // Click on the close link
                if (e.target.tagName == "A" && e.target.classList.contains('close')) {
                    self.alertBanner.remove();
                    dataLayer.push({'event' : 'start-banner', 'action' : 'close'})

                    var alertId = self.alertBanner.dataset.alertId;
                    var hiddenBanners = Cookies.getJSON('hidebanners');
                    if (typeof hiddenBanners != "undefined") {
                        hiddenBanners.push(alertId)
                        Cookies.set('hidebanners',hiddenBanners);
                    } else {
                        Cookies.set('hidebanners','["' + alertId + '"]')
                    }
                } else if (e.target.tagName == "A" && e.target.classList.contains('call')) {
                    dataLayer.push({'event' : 'start-banner', 'action' : 'call'})
                    window.location = e.target.href
                } else if (e.target.tagName == "A") {
                    dataLayer.push({'event' : 'start-banner', 'action' : e.target.dataset.action || 'click'})
                    window.location = e.target.href
                }
            })
        })

    }

    showBanner(banner) {
        //console.log('The banner you will be shown is:',banner)
        const self = this;
        // find the right template
        var bannerContent = document.querySelector('template[data-type="' + banner.type + '"]').content.cloneNode(true);
        var avoidEls = []; // initialize empty array for IntersectionObserver
        
        // replace any placeholder text
        if (banner.link) {
            var linkTag = bannerContent.querySelector('a[data-link]');
            if (linkTag) {
                if (banner.link.indexOf('http') > -1) { // external link
                    linkTag.setAttribute('href',banner.link)
                } else {
                    linkTag.setAttribute('href',linkTag.getAttribute('href').replace('[link]', banner.link))
                }
            }
        }
        if (banner.content) {
            for (var property in banner.content) {
                var tagToReplace = bannerContent.querySelector('[data-content-' + property + ']')
                if (tagToReplace) {
                    tagToReplace.innerHTML = tagToReplace.innerHTML.replace('[content.' + property + ']', banner.content[property])
                }
            }
        }

        // Updates the countdown remaining months and days based on the countdown date
        if (banner.countdown) {
            var currentDate = new Date();
            var countdownDate = new Date(banner.countdown);
            var daysRemaining = Math.ceil((countdownDate - currentDate) / (1000 * 60 * 60 * 24));
            var monthsRemaining = Math.floor(daysRemaining / 30);

            var countDownText = '';

            daysRemaining = daysRemaining % 30;

            if (monthsRemaining > 0) {
                countDownText += `<b>${monthsRemaining}</b> Month${monthsRemaining > 1 ? 's' : ''}, `;
            }

            if (daysRemaining > 0) {
                countDownText += `<b>${daysRemaining}</b> Day${daysRemaining > 1 ? 's' : ''}`;
            }

            bannerContent.querySelector('.countdown-period').innerHTML = countDownText;


            /**
             * Sticky countdown banner behavior
             */
            avoidEls = document.querySelectorAll('#footer-cta');
            self.alertBanner.setAttribute('data-sticky', 'true');

            window.addEventListener('scroll', function(e) {
                var distanceScrolled = 0;
                distanceScrolled = document.documentElement.scrollTop;

                if (distanceScrolled < 1) {
                    self.alertBanner.setAttribute('data-sticky', 'true');
                } else {
                    self.alertBanner.removeAttribute('data-sticky');
                }
            })
        } else {
            avoidEls = document.querySelectorAll('#hero, #footer-cta');
        }
        
        // insert into placeholder
        self.alertBanner.appendChild(bannerContent);
        self.alertBanner.setAttribute('data-alert-id', banner.id);



        /**
         * Intersection observer for triggering the start date banner
         */
        var observer = new IntersectionObserver(function(entries, observer){
            let avoidElsIntersecting = false;
            entries.forEach(function(entry){
                //console.log(entry);
                if (entry.isIntersecting) avoidElsIntersecting = true;
            });
            if (avoidElsIntersecting) {
                self.alertBanner.classList.add('offscreen');
            } else {
                self.alertBanner.classList.remove('offscreen');
            }
        });
        avoidEls.forEach(function(el) {
            observer.observe(el);
        });
    }
}

export default Banners;